import * as React from "react";
import { Link, PageProps } from "gatsby";
import Layout from "../components/layout/layout";
import { Breadcrumb } from "../components/breadcrumb/breadcrumb";
import Container from "../components/container/container";
import * as styles from "./cerez-politikasi.module.scss";

const CookiePolicyPage: React.FC<PageProps> = ({ location }: PageProps) => {
  return (
    <Layout
      hideTips={true}
      seoConfig={{
        title: "Çerez Politikası",
        description:
          "Opella Healthcare Tüketici Sağlığı A.Ş.(“Şirket” veya “Opella”) tarafından işletilmekte olan Bisolnatur® websitesi çerez politikasını inceleyebilirsiniz.",
      }}
    >
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <Container>
          <section className={styles.holder}>
            <h1>Çerez (Cookie) Politikası</h1>
            <p>
              <b>Son güncelleme tarihi:</b> 15 Kasım 2018
              <br />
              <br />
              İşbu Çerez Politikası’nın amacı <b>(“Çerez Politikası”)</b>,
              Opella Healthcare Tüketici Sağlığı A.Ş.{" "}
              <b>(“Şirket” veya “Opella”) </b> tarafından işletilmekte olan
              www.bisolnatur.com.tr internet sitesinde <b>(“Site”)</b>{" "}
              kullanılan çerezlere ilişkin koşul ve şartları tespit etmektir.
              <br />
              <br />
              Kişisel verilerin işlenmesine ilişkin{" "}
              <Link to="/gizlilik-politikasi-ve-kvkk-aydinlatma-metni/">
                <b>aydınlatma metni ve gizlilik politikasına bu link</b>
              </Link>{" "}
              üzerinden erişebilirsiniz.
              <br />
              <br />
              Çerez Politikası’nın tamamının veya belirli maddelerinin
              yenilenmesi durumunda Çerez Politikası’nın yürürlük tarihi ve
              versiyonu güncellenecektir. Politika Site’de yayımlanır ve İlgili
              kişi’lerin talebi üzerine ilgili kişilerin erişimine sunulur.
            </p>
            <h2 className="orange underline">
              Çerez nedir, çerezler ile hangi veriler işlenmektedir?
            </h2>
            <p>
              Çerez; bir internet sitesinin veya bir mobil uygulamanın düzgün
              bir şekilde çalışması, kullanıcı deneyiminin iyileştirilmesi,
              sitenin geliştirilmesi ve optimize edilmesi, daha uygun, ilgiye
              dayalı reklam sunma, ziyaretçiler için ilgi çekici ve
              kişiselleştirilmiş bir internet sitesi/uygulama ve reklam portföyü
              sunmak amacıyla cihazlara (bilgisayar, akıllı telefon, tablet)
              yerleştirilen küçük veri parçacıklarıdır.
              <br />
              <br />
              Çerezler, internet sitelerinin veya mobil platformların
              tercihlerinizi hatırlamasında yardımcı olurlar. (Örneğin Site’ye
              giriş yaparken girdiğiniz bilgiler, dil, konum ve diğer
              tercihler). Böylelikle Site veya mobil uygulamalarımızı ziyaret
              ettiğinizde söz konusu bilgileri tekrar girmeniz gerekmez.
              <br />
              <br />
              Bu kapsamda, Şirket tarafından işlenen çerezlerle toplanan
              verilerin hangileri olduğu sıralanmıştır:
              <br />
              <br />● <i>Müşteri Bilgisi</i>
              <br />● <i>Cihaz Bilgisi</i>
              <br />● <i>Davranışlar</i>
              <br />● <i>Demografik Bilgiler</i>
              <br />● <i>Pazarlama Bilgisi</i>
              <br />● <i>Davranışsal Reklamcılık</i>
              <br />
              <br />
              Çerez kullanımı sırasında geri döndürülemeyecek şekilde anonim
              hale getirilen verilere ilişkin işleme faaliyetleri işbu Çerez
              Politikası hükümleri ile bağlı olmaksızın gerçekleştirilecektir.
            </p>
            <h2 className="orange underline">
              Çerezleri ne için kullanıyoruz?
            </h2>
            <p>
              Şirket olarak çerezleri:
              <br />
              <br />
              ● Site’yi ve Şirket’e ait diğer web sitelerimizi geliştirmek ve
              siz İnternet kullanıcılarına daha iyi bir hizmet ve deneyim
              sunmak,
              <br />
              ● Site’yi ziyaret eden kişilerin ilgi alanlarına yakın olan
              içerikleri sunmak amacıyla bu kişilerin Site’yi kullanımlarına
              ilişkin bilgileri toplayarak diğer internet siteleri ve
              platformlarda hedeflenmiş reklam ve ticari iletişim faaliyetleri
              yapabilmek veya bir reklamın size görüntülenme sayısını
              kısıtlamak,
              <br />
              ● Ayrıca Opella ve Opella dışı web sitelerindeki reklam
              kampanyalarının verimliliğini ölçmek,
              <br />
              ● Ziyaret ettiğiniz web sitelerini hatırlamak,
              <br />
              ● Kişiselleştirilmiş çevrimiçi deneyiminin sunulması, web
              sitelerimizin tercihlerinizi (ör. kullanıcı adınız, bulunduğunuz
              bölge veya kullandığınız dil) hatırlamasını sağlaması, video
              izleyebilmeniz ve forumlar gibi sosyal araçları kullanabilmeniz
              gibi işlevselliğe yönelik amaçların gerçekleştirilmesi,
              <br />
              ● Google Analytics ve Youtube gibi üçüncü taraf hizmet
              sağlayıcılarının kullanılarak Site’mizi nasıl kullandığınızı
              analiz etmek ve ölçmek,
              <br />
              ● Yorumları, oyları, sayfaları, yer imlerini paylaşmanız ve sosyal
              ağlar ile sosyal araçlara daha kolay erişim sağlamanızı sağlamak,
              <br />
              <br />
              amaçlarıyla kullanmaktayız.. Bu amaç doğrultusunda çerez,
              terminaline yüklenmeden önce İnternet kullanıcısının onayının
              alınmasını (ticari türdeki çerezler) sağlayabilen, sonraki
              kısımlarda açıklanacak farklı çerez türleri kullanmaktadır.
            </p>
            <h2 className="orange underline">
              Çerezleri kim kullanır, çerezle toplanan verileri kimlere ve hangi
              amaçla aktarmaktayız?
            </h2>
            <p>
              Site üzerinden toplanan çerezleri, Opella olarak biz kullanırız.
              Ayrıca, bağlı olduğumuz şirketler, web sitesi sözleşmelerimizi
              yerine getiren iş ortaklarımız ve bize bağlı çalışan acente,
              ticari temsilci ve ticari vekil gibi tacir yardımcılarımız
              kullanabilirler.
              <br />
              <br />
              Şirket, çerezlerle elde ettiği bilgileri İlgili Kişi deneyiminin
              geliştirilmesi (iyileştirme ve kişiselleştirme dâhil), İlgili
              Kişi’nin güvenliğini sağlamak, hileli ya da izinsiz kullanımları
              tespit etmek, operasyonel değerlendirme araştırılması, Platform
              hizmetlerine ilişkin hataların giderilmesi ve işbu Çerez
              Politikası’nda yer alan amaçlardan herhangi birisini
              gerçekleştirebilmek için SMS gönderimi yapanlar da dahil olmak
              üzere dış kaynak hizmet sağlayıcıları, hosting (barındırma)
              hizmeti aldığımız yurtdışındaki bağlı şirketler ve diğer
              barındırma hizmet sağlayıcıları (hosting servisleri), hukuki
              destek hizmeti sağlayan avukatlar ve danışmanlar, araştırma
              şirketleri, çağrı merkezleri gibi üçüncü kişiler ile düzenleyici
              ve denetleyici kurumlar, bu bilgileri tabi olduğu mevzuatta açıkça
              talep etmeye yetkili olan kamu kurum veya kuruluşlar ile hukuki
              yükümlülüklerimizin yerine getirilmesi amacıyla
              paylaşılabilecektir.
            </p>
            <h2 className="orange underline">
              Hangi bilgilerinizi çerezlerimize kaydetmeyiz?
            </h2>
            <p>
              Adresiniz, şifreniz, kredi ya da ödeme kart bilgileriniz gibi
              hassas nitelikteki kişisel verileriniz çerezlere kaydedilmez.
            </p>
            <p>
              <b>Opella’da Çerezlerin Kullanımı - Çerez Kategorileri</b>
            </p>
            <h2 className="orange underline">Kesinlikle gerekli çerezler</h2>
            <p>
              Bu cookie’ler, Opella sitelerinin düzgün bir şekilde çalışabilmesi
              için gereklidir, sitelerimizde gezinmenizi ve site özelliklerinden
              faydalanmanızı sağlarlar. Aynı oturum içerisinde bir sayfaya geri
              dönüldüğünde önceki eylemleri hatırlama (ör. metin girişi) buna
              örnek verilebilir.
              <br />
              <br />
              Bu cookie’ler kimliğinizi tanımlamaz. Eğer bu cookie’leri kabul
              etmezseniz, web sitesindeki veya sitenin bazı bölümlerindeki
              performans etkilenebilir.
            </p>
            <br />
            <h3 className="blue">● Ayarlarınızı kaydetme</h3>
            <p>
              Hizmetlerimizde kontrol ettiğiniz bazı ayarlar, size ilgili
              bilgileri sağlamak veya deneyimi size uyarlamak için bir çerez
              belirlememizi gerektirir. Bunlar, Hizmetlerimizi ziyaret
              ettiğinizde kullandığımız çerezlere ve teknolojilere karar vermek
              için çerez izni bilgilerini saklamayı içerir.
            </p>
            <br />
            <h3 className="blue">● Alt sayfalardaki bilgilerin takibi</h3>
            <p>
              Hizmetlerimizi ziyaretiniz sırasında bilgi verdiğinizde, farklı
              alt sayfalarda neleri seçtiğiniz veya girdiğinizi hatırlamak için
              verileri bir çerezde saklarız. Bunun örnekleri arasında, alışveriş
              sepetlerinizin içeriğinin ve bilgilerinizin çevrimiçi
              mağazalarımızdaki ödeme işlemi sırasında kaydedilmesi sayılabilir.
            </p>
            <h2 className="orange underline">İşlevsellik</h2>
            <p>
              Bu cookie’ler, daha kişiselleştirilmiş bir çevrimiçi deneyim için,
              web sitelerimizin tercihlerinizi (ör. kullanıcı adınız,
              bulunduğunuz bölge veya kullandığınız dil) hatırlamasını sağlar.
              Cookie’ler aynı zamanda ziyaretçilerin video izleyebilmelerini,
              oyun oynayabilmelerini ve bloglar, sohbet odaları ve forumlar gibi
              sosyal araçları kullanabilmelerini sağlar.
              <br />
              <br />
              Bu cookie’lerin topladığı bilgiler, kullanıcı adınız veya profil
              resminiz gibi kimliğinizi tanımlayabilecek gizli verileri
              içerebilir.
              <br />
              <br />
              Eğer bu cookie’leri kabul etmezseniz, web sitesinin performans ve
              işlevselliği etkilenebilir ve web sitesindeki bazı içeriklere
              erişim sağlanamayabilir.
              <br />
              <br />
              Yorumları, oyları, sayfaları, yer imlerini paylaşmanızı sağlar ve
              sosyal ağlar ile sosyal araçlara daha kolay erişim sunmaya
              yardımcı olur.
              <br />
              <br />
              <b>Facebook:</b> Facebook’un sosyal eklentilerine ilişkin bir
              liste ve açıklama için{" "}
              <a
                href="https://developers.facebook.com/docs/plugins"
                target="_blank"
                rel="noreferrer"
              >
                <b>tıklayınız.</b>
              </a>
              <br />
              <b>Twitter:</b> Geliştiricilere yönelik davranış kuralları için{" "}
              <a
                href="https://dev.twitter.com/overview/terms/agreement-and-policy"
                target="_blank"
                rel="noreferrer"
              >
                <b>tıklayınız.</b>
              </a>
              <br />
              <b>YouTube:</b> YouTube hakkında ayrıntılı bilgi için{" "}
              <a
                href="https://support.google.com/youtube/answer/2801895"
                target="_blank"
                rel="noreferrer"
              >
                <b>tıklayınız.</b>
              </a>
              <br />
              <b>LinkedIn:</b> LinkedIn hakkında ayrıntılı bilgi için{" "}
              <a
                href="https://www.linkedin.com/legal/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                <b>tıklayınız.</b>
              </a>
            </p>
            <h2 className="orange underline">Analiz</h2>
            <p>
              Kullanıcıların Hizmetlerimizle nasıl etkileşimde bulunduğunu
              ölçmek için üçüncü taraf hizmetlerini kullanıyoruz.
            </p>
            <br />
            <h3 className="blue">● Google Analitik</h3>
            <p>
              Google Analitik bilgilerinin toplanması ve kullanımıyla ilgili
              daha fazla bilgi için Google’ın Veri gizliliği ve güvenlik
              sayfasını ziyaret edin. Bir tarayıcı uzantısı olan “gaoptout”
              kullanarak tarayıcı genelinde Google Analitik veri toplama
              özelliğini devre dışı bırakabilirsiniz.
              <br />
              <br />
              <b>Daha fazla bilgi:</b>{" "}
              <a
                href="https://www.google.com/policies/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                <b>https://www.google.com/policies/privacy/</b>
              </a>
              <br />
              <b>
                Tüm web sitelerinde Google Analytics tarafından izlenmemeyi
                tercih etmek için:
              </b>{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
                rel="noreferrer"
              >
                <b>https://tools.google.com/dlpage/gaoptout</b>
              </a>{" "}
              adresini ziyaret edin
              <br />
              <br />
              <b>• YouTube İzlemesi:</b> Bu çerezler YouTube tarafından kendi
              hizmetlerinin kullanımı izlemek için ayarlanır. YouTube Çerezleri
              yalnızca oynat düğmesine bastığınızda yüklenir.
              <br />
              <br />
              <b>Daha fazla bilgi:</b>{" "}
              <a
                href="https://policies.google.com/technologies/product-privacy"
                target="_blank"
                rel="noreferrer"
              >
                <b>https://policies.google.com/technologies/product-privacy</b>
              </a>
            </p>
            <h2 className="orange underline">Reklam</h2>
            <p>
              Bu cookie’ler size ve ilgi alanlarınıza yakın olan içerikleri
              sunmak amacıyla kullanılır. Hedefli reklamlar sunmak veya bir
              reklamın size görüntülenme sayısını kısıtlamak amacıyla
              kullanılabilir. Ayrıca Opella ve Opella dışı web sitelerindeki
              reklam kampanyalarının verimliliğini ölçmemize de yardımcı olur.
              <br />
              <br />
              Bu cookie’leri, ziyaret ettiğiniz web sitelerini hatırlamak
              amacıyla kullanabilir ve bu bilgileri reklam verenler ve kendi
              firmalarımız dahil olmak üzere üçüncü partilerle paylaşabiliriz.
            </p>
            <br />
            <h3 className="blue">
              ● Google Analitik Reklamcılık Özelliklerini
            </h3>
            <p>
              Belirli davranışlara, demografik ve ilgi alanı verilerine dayalı
              olarak yeniden pazarlama kitleleri oluşturmak ve bu listeleri
              Google AdWords ile paylaşmak için Google Analitik Reklamcılık
              Özelliklerini kullanıyoruz. Daha fazla bilgi için Google’ın Reklam
              Gizliliği ve Şartlarını ziyaret edin. Tarayıcı genelinde
              kişiselleştirilmiş reklamları devre dışı bırakmak için bir
              tarayıcı uzantısı kullanın.
            </p>
            <br />
            <h3 className="blue">● Google Adwords Remarketing</h3>
            <p>
              Web sitemize yapılan geçmiş ziyaretlere dayalı olarak, hedefli
              çevrimiçi reklamlar sunmamıza yardımcı olmak için Google AdWords
              Yeniden Pazarlama`yı kullanıyoruz.
            </p>
            <br />
            <h3 className="blue">● Google Adwords Dönüşüm İzleyici</h3>
            <p>
              Google Reklamlarımızda tıklayan kullanıcıların sitemizde neler
              yaptığını belirlememize yardımcı olması için çerez kullanan Google
              Adwords Dönüşüm izleyiciyi kullanıyoruz.
            </p>
            <br />
            <h3 className="blue">● Facebook Pixel</h3>
            <p>
              Yeniden pazarlama kitleleri oluşturmak ve Facebook`ta yayınlanan
              reklam kampanyalarının performansını ölçmek için Facebook Pixel`i
              kullanıyoruz. Daha fazla bilgi için Facebook’un makalesini ziyaret
              edin. Hesap genelinde kişiselleştirilmiş reklamları devre dışı
              bırakmak için Reklam Tercihlerinizi ziyaret edin.
            </p>
            <h2 className="orange underline">
              Opella kullanılan çerezler hakkında sizi nasıl bilgilendirir?
            </h2>
            <p>
              www.bisolnatur.com.tr sitesine ilk kez girdiğinizde, sitede
              beliren yasal uyarı, bu sitede çerezlerin kullanıldığına dair sizi
              bilgilendirecek ve dilediğiniz takdirde bu site tarafından
              çalıştırılan ve kullanılan çerezlere ilişkin tüm bilgilerin
              bulunduğu detaylı bir sayfaya yönlenebilecek bir link içerecektir.
              Uyarıdan sonra Site’yi kullanmaya devam etmeniz halinde, Çerez
              Politikamızı kabul etmiş sayılacaksınız. Çerezleri kaldırma
              şansınız vardır. Çerezleri kaldırmanız durumunda internet sitemiz
              gerektiği gibi çalışmayabilir veya talep ettiğiniz hizmetlerin
              sunulması sırasında aksaklıklara neden olabilir.
              <br />
              <br />
              Çerez Politikası’nı kabul ederek çerezlerin Çerez Politikası
              kapsamında uyumlu şekilde kullanılmasına onay vermiş olursunuz.
              <br />
              <br />
              <b>Cookie Uyarısı</b>
              <br />
              <br />
              Sitemizden en iyi şekilde faydalanabilmeniz için çerezleri
              kullanıyoruz. Bu siteye giriş yaparak, çerez kullanımını ve çerez
              politikamızı kabul etmiş sayılıyorsunuz. Çerez politikamız
              hakkında detaylı bilgi almak için{" "}
              <Link to="/cerez-politikasi/">tıklayın.</Link>
            </p>
            <h2 className="orange underline">
              Çerezleri nasıl yönetebilirsiniz?
            </h2>
            <p>
              Zorunlu çerezler dışındaki çerezleri silebilir veya
              engelleyebilirsiniz fakat bu halde internet sitemiz gerektiği gibi
              çalışmayabilir.
              <br />
              <br />
              <Link to="/">“www.bisolnatur.com.tr”</Link> internet sitesini
              kullanarak çerezlerin Politika kapsamında uyumlu şekilde
              kullanılmasına onay vermiş olursunuz.
              <br />
              <br />
              <b>a. Bilgisayarda</b>
              <br />
              <br />
              İnternet tarayıcınızın çeşidine göre aşağıdaki adımları izleyerek,
              çerezler hakkında bilgi edinip, izin verme veya reddetme hakkınızı
              kullanabilirsiniz:
              <br />
              <b>Google Chrome:</b> Browser’ınızın “adres bölümünde” yer alan,
              “kilit işareti” veya “i” harfini tıklayarak, “Cookie” sekmesinden
              çerezlere izin verebilir veya bloke edebilirsiniz.
              <br />
              <br />
              <b>İnternet Explorer:</b> Browser’ınızın sağ üst köşesinde yer
              alan “Tool” veya “Araçlar” Bölümünden “Güvenlik” sekmesini
              tıklayın ve “izin ver” veya “izin verme” şeklinde çerez yönetimizi
              gerçekleştirin.
              <br />
              <br />
              <b>Mozilla Firefox:</b> Browser’ınızın sağ üst köşesinde yer alan
              “menüyü aç” sekmesini tıklayınız. “Seçenekler” görselini
              tıklayarak “Gizlilik ve Güvenlik” butonunu kullanarak çerez
              yönetiminizi yapınız.
              <br />
              <br />
              <b>Diğer browserlar</b> için de (opera, microsoft edge gibi),
              ilgili browser’ın yardım veya destek sayfalarını
              inceleyebilirsiniz.
              <br />
              <br />
              <b>b. Akıllı telefon veya tablette</b>
              <br />
              <br />
              <b>Android Tarayıcısı:</b> “Ana” ekranınızı açın, web tarayıcı
              simgenize dokunun; “Menu” (Menü) düğmesine ve “Settings” (Ayarlar)
              öğesine dokunun. Cihazınız ya mevcut ayarların bulunduğu bir menü
              seçeneği görüntüleyecek YA DA aşağıdaki üç seçenekten birini
              sunacaktır. Aşağıdaki seçimi yapın: Privacy &amp; Security
              (Gizlilik ve Güvenlik), Privacy (Gizlilik), Security (Güvenlik)
              seçeneklerinde, “Clear Cache” (Önbelleği Temizle) seçeneğine ve
              onaylamak için “OK” (Tamam) öğesine dokunun. “Clear all cookie
              data” (Tüm çerez verilerini temizle) seçeneğine ve ardından
              onaylamak için “OK” (Tamam) öğesine dokunun “Ana” Ekrana dönmek
              için “Home” tuşuna basın. Cihazınızı yeniden başlatın.
              <br />
              <br />
              <b>Google Chrome Tarayıcısı:</b> Tarayıcı araç çubuğunda, “Chrome
              menu” (Chrome menüsü) öğesine, “More tools” (Daha fazla araç) ve
              “Clear browsing data” (Tarayıcı verilerini temizle) seçeneklerine
              dokunun. Açılan kutuda, “Cookies and other site and plug-in data”
              (Çerezler ve diğer site ve eklenti verileri) ve “Cached images and
              files” (Önbelleğe alınan resimler ve dosyalar) onay kutularını
              seçin. Silmek istediğiniz veri miktarını seçmek için üst kısımdaki
              menüyü kullanın. Tümünü silmek için “beginning of time” (tüm
              zamanlar) öğesini seçin. “Clear browsing data” (tarayıcı
              verilerini sil) seçeneğine dokunun.
              <br />
              <br />
              <b>Safari:</b> Telefonunuzun “Ayarlar” bölümünden “safari”
              sekmesini seçip, “Gizlilik ve Güvenlik” Bölümünden tüm çerez
              yönetiminizi sağlayabilirsiniz.{" "}
              <a
                href="https://support.apple.com/en-us/HT201265"
                target="_blank"
                rel="noreferrer"
              >
                Bu linki
              </a>{" "}
              kullanarak detaylı bilgi alabilirsiniz.
            </p>
            <h2 className="orange underline">Kullanıcı anlaşması</h2>
            <p>
              Web sitemizi kullanmaya devam ederek, cihazınıza çerezlerin
              yerleştirilmesini kabul etmektesiniz. Çerezlerin cihazınıza
              yerleştirilmesini istemiyorsanız, lütfen internet tarayıcınızın
              ayarlarını tercihlerinize göre düzenleyin.
              <br />
              <br />
              Kalıcı çerezleri veya oturum çerezlerini reddederseniz, web
              sitesini, mobil uygulamayı ve mobil sitesini kullanmaya devam
              edebilirsiniz fakat web sitesinin, mobil uygulamanın ve mobil
              sitesinin tüm işlevlerine erişemeyebilirsiniz veya erişiminiz
              sınırlı olabilir. Mobil uygulamada söz konusu durum değişkenlik
              gösterebilmektedir. Çerezlerin kaldırılması veya silinmesi,
              kullanıcı deneyiminizi olumsuz yönde etkileyebilir.
              <br />
              <br />
              Çerezler ve çerez yönetimi ile ilgili daha fazla bilgi için web
              sitemizdeki{" "}
              <Link to="/gizlilik-politikasi-ve-kvkk-aydinlatma-metni/">
                <b>Kişisel Verilerin İşlenmesi Politikamızı</b>
              </Link>{" "}
              inceleyebilirsiniz.
            </p>
          </section>
        </Container>
      </Container>
    </Layout>
  );
};

export default CookiePolicyPage;
